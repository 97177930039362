import React from 'react'

const NotFound = () => {
  return (
    <div className="green-container">
      <div className="userContainer">
        <h1>Profile does not exist</h1>
      </div>
    </div>
  )
}

export default NotFound
